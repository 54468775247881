import logo from './logo.webp';
import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/list" element={<SubPage />} />
      </Routes>
    </Router>
  );
}

function LandingPage() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>
          Welcome to the openSuse Test Catalog
        </h2>
        <Link to="list" className="text-link">
          Browse
        </Link>
      </header>
    </div>
  );
}

function SubPage() {

  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState('');
  //const [tests, setResults] = useState([]);

  // Function to fetch tests with optional tags
  const fetchTests = async (tags = []) => {
    try {
      setLoading(true);
      setError(null);

      // Construct the query string based on tags
      const queryString = tags.length > 0 ? `?tags=${tags.join(',')}` : '';
      const response = await fetch(`https://backend-test-catolog.onrender.com/list${queryString}`);
      //const response = await fetch(`http://localhost:3001/list${queryString}`);

      if (!response.ok) {
        throw new Error('Failed to fetch tests');
      }

      const data = await response.json();
      setTests(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Event handler to fetch HPC-specific tests
  const handleHPCTestsClick = () => {
    fetchTests(['HPC']);
  };

    // Event handler to fetch SAP-specific tests
    const handleSAPTestsClick = () => {
      fetchTests(['sles4sap']);
    };

    const handleAutoyastTestsClick = () => {
      fetchTests(['autoyast']);
    };

    const handlePubliccloudTestsClick = () => {
      fetchTests(['publiccloud']);
    };

    const handleKernelTestsClick = () => {
      fetchTests(['kernel']);
    };

    const handleVirtualizationTestsClick = () => {
      fetchTests(['virtualization']);
    };

  // Event handler to fetch all tests
  const handleAllTestsClick = () => {
    fetchTests(); // No tags, fetches all tests
  };

  const handleClearData = () => {
    setTests([]);
  };


  /////////////////////////////////////
    // Function to fetch tests with optional tags
    const fetchTestsText = async (tags = []) => {
      try {
        setLoading(true);
        setError(null);
  
        const response = await fetch(`https://backend-test-catolog.onrender.com/search?q=${query}`);
        //const response = await fetch(`http://localhost:3001/search?q=${query}`);
  
        if (!response.ok) {
          throw new Error('Failed to fetch tests');
        }
  
        const data = await response.json();
        setTests(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleTextSearchTestsClick =  async () => {
    fetchTestsText();
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      fetchTestsText();
    }
  };

  // const fetchData = () => {
  //   //fetch('https://backend-test-catolog.onrender.com/list')
  //   fetch('http://localhost:3001/list')
  //     .then(response => response.json())
  //     .then(data => setData(data))
  //     .catch(error => console.error('Error Fetching Data', error));
  // };

  const insertLatestData = async () => {
    try {
      const response = await fetch('http://localhost:3001/sync-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        //TODO: Body will be from github parser
        body: JSON.stringify({testName: "New Test"})
      });
      const message = await response.text();
      console.log(message);
    } catch (error) {
      console.error('Error adding new tests', error);
    }
  };


  return(
    <div className="container mt-5">
      <h2 className="test-center mb-4">The Test Catalog (experimental)</h2>
      <p className="test-center mb-4">Provide feedback to schlad</p>

      <div className="d-flex mb-3 flex-wrap pt-5 pb-5 border-bottom border-top">
        <button className="btn btn-success me-2" onClick={handleAllTestsClick}>List All Tests with Description</button>
        <button className="btn btn-success me-2" onClick={handleHPCTestsClick}>HPC Tests</button>
        <button className="btn btn-success me-2" onClick={handleSAPTestsClick}>SLES4SAP Tests</button>
        <button className="btn btn-success me-2" onClick={handleAutoyastTestsClick}>Autoyast Tests</button>
        <button className="btn btn-success me-2" onClick={handlePubliccloudTestsClick}>Public Cloud Tests</button>
        <button className="btn btn-success me-2" onClick={handleKernelTestsClick}>Kernel Tests</button>
        <button className="btn btn-success me-2" onClick={handleVirtualizationTestsClick}>Virtualization Tests</button>

        <button className="btn btn-danger ms-auto" onClick={handleClearData}>Clear Listed Tests</button>
      </div>
      <div className="d-flex mb-4 justify-content-between mt-5 ms-auto lex-wrap pb-5 border-bottom">
        <input className="ms-auto" type="text" value={query} onChange={handleInputChange}  onKeyDown={handleEnterPress} placeholder="Search..."/>
        <button className="btn btn-success me-2" onClick={handleTextSearchTestsClick}>Search</button>

      </div>

      {/* Render loading, error, or list of tests */}
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}

      <ul className="list-group">
        {tests.map((test, index) => (
          <li key={index} className="list-group-item">
            <strong>{test.testName}</strong>
            <div className="mt-2 text-muted">{test.testDescription}</div>
          </li>
        ))}
      </ul>
    <div className="d-flex justify-content-between mt-5">
      <button className="btn btn-success btn-sm" onClick={() => window.history.back()}>Go back</button>
      <button className="btn btn-danger ms-auto btn-sm" onClick={insertLatestData}>Fetch (insert) latest data</button>
    </div>
  </div>
  );

}

export default App;
